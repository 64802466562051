import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyBz2plmN9164xP19GTpPbOfWEu-WSP7uP0",
  authDomain: "awtdemo-24187.firebaseapp.com",
  databaseURL: "https://awtdemo-24187.firebaseio.com",
  projectId: "awtdemo-24187",
  storageBucket: "awtdemo-24187.appspot.com",
  messagingSenderId: "123151217470",
  appId: "1:123151217470:web:20512b2ed69991e77af26f",
  measurementId: "G-GQC2M3F6F4",
};
// Initialize Firebase

var fire = firebase.initializeApp(firebaseConfig);

export const db = fire.database().ref("/titan");

export default fire;

export const get = () => {
  return db;
};

// export const updateProfit = (data) => {
//   db.set({ profit: data });
// };

export const reset = () => {
  // return db.update(p)
};
